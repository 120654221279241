import {computed} from "vue"
import {useStore} from "vuex"

const useAuth = () => {
  const store = useStore()

  const loginUser = async (user) => {
    store.dispatch("datos/setDatosInit")
    store.dispatch("documentos/setDocumentosInit")
    store.dispatch("facturas/setFacturasInit")
    return await store.dispatch("auth/loginUser", user)
  }

  const logoutUser = async () => {
    store.dispatch("datos/setDatosInit")
    store.dispatch("documentos/setDocumentosInit")
    store.dispatch("facturas/setFacturasInit")
    return await store.dispatch("auth/logoutUser")
  }

  return {
    loginUser,
    logoutUser,
    nombre: computed(() => store.getters["auth/nombre"]),
  }
}

export default useAuth
